.fullContainer {
  display: flex; /* Utilisez un conteneur flex pour centrer le contenu */
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
  background: linear-gradient(
    180deg,
    #f7ebfc 0%,
    var(--token-20257545-130a-452b-8942-48d79a1f3d66, rgb(255, 255, 255)) 100%
  );
  height: 100vh;
}

.loginWrap {
  max-width: 1024px;
  padding: 0px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.loginContainer {
  height: 50%;
  background: white;
  border-radius: 15px;
}

.connectGoogleButton {
  border-radius: 9999px;
  /* border: 1px solid var(--gray-100, #f2f2f2); */
  background: var(--gray-0, #fff);
  color: #000;
  width: 100%;
  text-align: center;
  box-shadow: 0px 1.15px 2.3px 0px rgba(0, 0, 0, 0.25),
    0px 0px 0px 0px rgba(0, 0, 0, 0.08);
}

.loginPage p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15.914px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.825px; /* 137.143% */
  margin-bottom: 20px;
}

.logoLogin {
  padding: 10px 0px;
  width: 50%;
}

.card {
  border-radius: 35px;
  box-shadow: 2px 17px 18px -9px rgba(0, 0, 0, 0.07);
  border: 18px solid var(--Transclucide-stroke, rgba(255, 255, 255, 0.35));
  width: 460px;
}

.leftSide {
  flex: 1; /* Prend la moitié de l'espace disponible */
  display: flex; /* Utilisez un conteneur flex pour centrer le contenu */
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
  background: linear-gradient(
    126deg,
    #ffd788 28.79%,
    #ffbfbf 64.77%,
    #c5c3ff 100%
  );
}

.rightSide {
  flex: 1; /* Prend la moitié de l'espace disponible */
  padding: 20px; /* Ajout d'un peu d'espacement pour le texte */
  color: black;
  justify-content: center;
  align-items: center;
}

.avisLogin {
  /* max-width: 80%; */
  padding: 10%;
}

.rightSide h2 {
  padding-left: 0px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="email"],
input[type="text"],
input[type="password"] {
  width: 60%;
  padding: 10px;
  height: 56px;
  background-color: #fff;
  border-radius: 10px;
  text-align: left;
  padding-left: 15px;
}

.forgot-password-link {
  color: black;
  text-decoration: none;
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
}

.logoOverlay {
  position: absolute;
  top: -59px;
  left: 57.5%;
  transform: translate(-50%, -50%);
  width: 30%;
  z-index: 10;
}

.register-link {
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.825px; /* 137.143% */
}

.lineLogin {
  position: relative; /* Assurez-vous que le contenu est positionné relativement */
  color: #d4d4d4;
}

.lineLogin::before,
.lineLogin::after {
  content: ""; /* Ajoutez du contenu aux pseudos-éléments */
  display: inline-block; /* Assurez-vous qu'ils sont affichés en tant que blocs en ligne */
  width: 20%; /* Largeur de la ligne */
  height: 1px; /* Hauteur de la ligne */
  background-color: #d4d4d4; /* Couleur de la ligne */
  margin: 0 8%; /* Marge pour espacer la ligne du texte "OR" */
}

.error-message {
  font-size: 14px;
  margin-bottom: 30px;
  color: red;
}

#mainRegister {
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: center; /* Centre verticalement */
  align-items: center; /* Centre horizontalement */
  min-height: 100vh; /* Occupe au moins toute la hauteur de la fenêtre */
  background-color: #f0f0f0; /* Couleur de fond (ajustez selon vos besoins) */
}

.wrap {
  text-align: center; /* Centre le texte horizontalement */
  padding: 20px;
}

h1 {
  font-size: 36px; /* Taille de police pour l'icône (ajustez selon vos besoins) */
}

h3 {
  font-size: 24px; /* Taille de police pour le titre (ajustez selon vos besoins) */
}

p {
  font-size: 18px; /* Taille de police pour le paragraphe (ajustez selon vos besoins) */
}

.check-group {
  display: flex;
  align-items: center; /* Aligner les éléments verticalement au centre */
}

/* CSS pour décaler le texte à droite */
/* label {
  margin-left: 10px;
} */

/* 1024px RESPONSIVE */

@media screen and (max-width: 1024px) {
  .leftSide {
    display: none;
  }
}
