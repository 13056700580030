input[type="email"],
input[type="text"],
input[type="password"] {
  width: 95%;
  padding: 10px;
  height: 56px;
  background-color: #f2f2f2;
  /* border-radius: 10px; */
  text-align: left;
  padding-left: 15px;
  border-radius: 0px 10px 10px 10px;
}

select {
  width: 98% !important;
}

.keywordSelectOnboarding {
  background-color: #edddf7;
  padding: 10px;
  border-radius: 10px;
}

.keywordSelectOnboardingText {
  font-weight: 600;
}

.customModal {
  width: 70% !important;
}

/* styles.css (ou tout autre fichier CSS que vous utilisez) */
.customModal .ant-modal-content {
  /* height: 60vh; */
  padding: 0px;
  border-radius: 17px;
  border: 21px solid rgba(255, 255, 255, 0.47);
}

.customModal .ant-modal-body {
  display: flex; /* Utilisez Flexbox pour séparer la modal en deux parties */
}

.customModal .leftPart,
.customModal .rightPart,
.customModal .rightPart2 {
  padding: 20px;
}

.customModal .leftPart {
  flex: 0.5;
  background: #fff;
  padding: 30px;
}

.customModal .leftPartOnboarding {
  flex: 1;
  background: #fff;
  padding: 30px;
}

.textArea {
  padding: 10px;
  height: 56px;
  background-color: #f2f2f2;
  border-radius: 0px 10px 10px 10px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 15px;
  color: #000;
}

.keywords-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.closeAddKeyword {
  position: absolute;
}

.keyword-block {
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  flex-basis: calc(
    33.333% - 20px
  ); /* Ajuste en fonction du gap et du nombre de colonnes */
  display: flex;
  flex-direction: column;
}

.keyword-block-add {
  background: var(--Grey-Background, #fafafa);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  flex-basis: calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrage vertical */
  text-align: center;
  align-items: center;
  width: 100%;
}

.plusKeyword {
  color: #9e00ff;
  text-align: center;
  font-size: 55px;
  font-style: normal;
  font-weight: 300;
  line-height: 23.5px; /* 50% */
  margin-bottom: 20px;
}

.textAddKeyword {
  color: var(--Grey-400, var(--Grey-400, #353535));

  /* Body */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 167.857% */
}

.keywords-container h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.SubTitleInput {
  color: var(--Gray-500, var(--grey-500-black, #000));

  /* Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 195.833% */
}

.add-keyword-block {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 167.857% */
  background: var(--Grey-Background, #fafafa);
}

/* Assurez-vous que les champs de saisie s'étendent à la largeur du conteneur */
.keyword-block input {
  width: 100%;
}

.keyword-block button {
  align-self: flex-end;
  margin-top: auto; /* Place le bouton en bas du conteneur */
}

.customModal .leftPart h2 {
  padding-left: 0px;
  font-family: Mochiy Pop One;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.5px; /* 67.143% */
  padding-top: 30px;
}

.customModal .leftPart p {
  color: var(--Grey-300, #666);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25.5px; /* 141.667% */
}

.customModal .rightPart p {
  color: var(--Grey-500, #000);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 200% */
}

.customModal .rightPart2 p {
  color: var(--Grey-500, #000);
  text-align: left;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.footerButtonSteps {
  text-align: center;
}
.footerButtonSteps2 {
  text-align: center;
}

.footerButtonSteps3 {
  text-align: center;
}

.footerButtonSteps4 {
  text-align: center;
}

.selectOnboarding {
  background-color: #f2f2f2;
}

.activeLinkOnboarding {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.5px; /* 139.286% */
  text-decoration-line: underline;
  cursor: not-allowed;
}

.footerButtonSteps::after {
  content: "...."; /* Four dots representing the circles */
  letter-spacing: 10px; /* Space between the dots */
  font-size: 80px; /* Size of the dots */
  line-height: 1; /* Adjust line height */
  background: linear-gradient(
    90deg,
    #9e00ff 25%,
    /* First circle entirely black */ #d9d9d9 25%,
    /* Start of second circle */ #d9d9d9 100%
      /* All remaining circles are gray */
  ); /* Gradient to create the circles */
  -webkit-background-clip: text; /* Clip the background */
  background-clip: text; /* Clip the background */
  color: transparent; /* Make the original text color transparent so the gradient shows */
}

.footerButtonSteps2::after {
  content: "...."; /* Four dots representing the circles */
  letter-spacing: 10px; /* Space between the dots */
  font-size: 80px; /* Size of the dots */
  line-height: 1; /* Adjust line height */
  background: linear-gradient(
    90deg,
    #9e00ff 25%,
    /* First circle gray */ #9e00ff 25%,
    /* Start of second circle */ #9e00ff 50%,
    /* End of second circle */ #d9d9d9 50%,
    /* Start of third circle */ #d9d9d9 100%
      /* All remaining circles are gray */
  ); /* Gradient to create the circles */
  -webkit-background-clip: text; /* Clip the background */
  background-clip: text; /* Clip the background */
  color: transparent;
}

.footerButtonSteps3::after {
  content: "...."; /* Four dots representing the circles */
  letter-spacing: 10px; /* Space between the dots */
  font-size: 80px; /* Size of the dots */
  line-height: 1; /* Adjust line height */
  background: linear-gradient(
    90deg,
    #9e00ff 25%,
    /* First circle gray */ #9e00ff 25%,
    /* Start of second circle */ #9e00ff 50%,
    /* End of second circle */ #9e00ff 50%,
    /* Start of third circle */ #d9d9d9 100%
      /* All remaining circles are gray */
  ); /* Gradient to create the circles */
  -webkit-background-clip: text; /* Clip the background */
  background-clip: text; /* Clip the background */
  color: transparent;
}

.footerButtonSteps4::after {
  content: "...."; /* Four dots representing the circles */
  letter-spacing: 10px; /* Space between the dots */
  font-size: 80px; /* Size of the dots */
  line-height: 1; /* Adjust line height */
  background: linear-gradient(
    90deg,
    #9e00ff 25%,
    /* First circle gray */ #9e00ff 25%,
    /* Start of second circle */ #9e00ff 50%,
    /* End of second circle */ #9e00ff 50%,
    /* Start of third circle */ #9e00ff 100%
      /* All remaining circles are gray */
  ); /* Gradient to create the circles */
  -webkit-background-clip: text; /* Clip the background */
  background-clip: text; /* Clip the background */
  color: transparent;
}

.labelFormOnboarding {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.5px;
}

.customModal .rightPart img {
  max-width: 40%;
}
.customModal .rightPart2 img {
  max-width: 30%;
}

.customModal .keyword-block input[type="text"],
input[type="email"],
input[type="url"] {
  height: 35px;
}

.customModal .special p {
  line-height: 30px;
}

.customModal .highlightedOnboard {
  background: #ffd600;
  background-blend-mode: darken;
}

.customModal .rightPart {
  background-color: #fbfbfb;
  flex: 0.5;
  padding: 20px;
  display: flex; /* Add this line */
  justify-content: center; /* Add this line */
  align-items: center; /* Add this line */
  flex-direction: column;
}

.customModal .alertPurpleOnboarding {
  margin-bottom: 30px;
  text-align: center;
  padding: 10px;
  color: #9e00ff;
  text-align: center;

  border-radius: 12px;
  background: #f6e6ff;

  align-items: center;

  /* H3 */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.customModal .rightPart2 {
  background-color: #fbfbfb;
  flex: 0.5;
  padding: 20px;
  display: flex; /* Add this line */
  justify-content: center; /* Add this line */
  align-items: left; /* Add this line */
  flex-direction: column;
}

.customModal h3 {
  padding-top: 20px;
  color: var(--Grey-500, #000);
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.825px; /* 149.207% */
}

.rightPart h2 {
  padding-left: 0px;
  margin-bottom: 5px;
}

.rightPart p {
  padding-left: 0px;
  margin-bottom: 30px;
}

.rightPart .label {
  margin-left: 0px;
}

.rightPart2 h2 {
  padding-left: 0px;
  margin-bottom: 5px;
}

.rightPart2 p {
  padding-left: 0px;
  margin-bottom: 10px;
}

.rightPart2 .label {
  margin-left: 0px;
}

.alignVert {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.platforms-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.platform-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  position: relative;
}

.platform-card:hover {
  border-color: #9e00ff;
}

.platform-card.selected {
  border: 2.5px solid #9e00ff;
}

.platform-card.premium {
  cursor: not-allowed;
  border-color: #ff9800; /* Couleur de la bordure pour les cartes premium */
}

.platform-card.premium .premium-tag {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  font-size: 0.75rem;
  border-radius: 4px;
  text-transform: uppercase;
}

.platform-card i {
  font-size: 2rem;
}

.platform-card i,
.platform-card .platform-icon {
  font-size: 2rem;
}

.platform-card .platform-icon {
  width: 50px;
  height: 50px;
}

.platform-card span {
  margin-top: 8px;
  font-size: 1rem;
}

.linkedin-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.linkedin-message p {
  margin: 0;
}

.linkedin-message a {
  color: #007bff;
  text-decoration: underline;
}

.downloadExtension {
  margin-left: 5px;
}

/* NAV CLOSED */
@media screen and (max-width: 1024px) {
  .customModal .ant-modal-body {
    display: block;
  }

  .customModal .leftPart h2 {
    padding-left: 0px;
    font-family: Mochiy Pop One;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding-top: 30px;
  }

  .customModal .rightPart {
    display: none;
  }

  .customModal .rightPart2 {
    display: none;
  }

  .customModal {
    width: 100%;
  }

  .customModal .leftPart p {
    color: var(--Grey-300, #666);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25.5px;
  }
}
