.BasicMain {
  margin-top: 200px;
}

.BasicMain ul li {
  margin-bottom: 10px;
  /* list-style-type: disc; */
  margin-left: 20px;
}

.BasicMain h4 {
  margin-bottom: 10px;
}

.BasicMain .textPart {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.toneSelection {
  gap: 20px;
  margin-top: 20px;
}

.languageSelection {
  margin-top: 20px;
}

.modal {
  position: relative;
  background: white;
  border-radius: 5px;
  padding: 20px;
  width: 80%;
  max-width: 700px;
  height: auto;
  max-height: 99%;
  box-sizing: border-box;
  overflow-y: auto;
}

p {
  font-size: 16px;
}

input[type="text"],
input[type="email"],
input[type="url"] {
  width: 95%;
  padding: 10px;
  height: 56px;
  background-color: #f2f2f2;
  border-radius: 0px 10px 10px 10px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 15px;
  color: #000;
}

.buttonOption {
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

h1 {
  font-size: 28px !important;
}

h1 p {
  font-weight: 400;
}

h2 {
  font-size: 24px !important;
  padding-left: 0px !important;
}

h3 {
  font-size: 20px !important;
  padding-left: 0px !important;
  text-align: left !important;
}

h4 {
  font-size: 18px !important;
  padding-left: 0px !important;
}

.buttonOption.selected {
  background-color: rgb(167, 0, 255);
  color: white;
  border-color: rgb(167, 0, 255);
}

.buttonLanding {
  background-color: rgb(167, 0, 255);
  border-radius: 10px;
  height: 100%;
  opacity: 1;
  padding: 10px 20px;
  color: white;
}

.buttonLanding:hover {
  background-color: rgb(149, 4, 226);
  border-radius: 10px;
  height: 100%;
  opacity: 1;
  padding: 10px 20px;
  color: white;
}

.textarea-description {
  width: 100%;
  margin-bottom: 10px;
  height: 150px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
}

#journalistPage .modal {
  position: absolute;
  z-index: 99;
  width: auto;
  padding: auto;
}

#journalistPage .close {
  padding: 0px;
  margin-right: 0px;
}

#journalistPage .cta {
  margin-left: 0px;
  margin-top: 10px;
}

.modal h2 {
  padding-left: 0px !important;
}

.modal h4 {
  margin-bottom: 20px;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 550px;
}

.close {
  position: absolute;
  right: 0;
  padding: 10px;
  margin-right: 10px;
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .BasicMain {
    margin-top: 120px;
  }
}
