#globalFooter {
  margin-top: 80px;
  background: #000000;
  padding: 60px 0 0px 0;
  border-radius: 30% 30% 0 0 / 30px 30px 0 0;
}
#globalFooter .wrap > img {
  margin-right: 30px;
}
#globalFooter li a:not(:last-of-type) img {
  margin-right: 30px;
}
#globalFooter .wrap ul,
#globalFooter img {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}
#globalFooter .wrap ul li {
  display: inline-block;
  color: #fff;
  /* cursor: pointer; */
}
#globalFooter .wrap ul li a {
  display: inline-block;
}
#globalFooter .wrap ul li:not(:last-of-type) {
  margin-right: 10px;
}

.h3footer {
  font-family: "Inter";
  font-size: 3.5rem;
  margin-bottom: 15px;
  line-height: 1;
  font-weight: 600;
}

.frf-water {
  display: none;
}

.frf-modal-input {
  color: #000000;
}

.prefooter {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.flagRadius {
  border-radius: 5px;
}

.footerLogo {
  margin-bottom: 60px;
}

.footerPreImg {
  display: flex;
  justify-content: flex-end; /* Aligner les éléments enfants à droite */
  margin-bottom: -11px;
  margin-right: 20%;
  margin-top: -9%;
}

/* .footerPreImg {
  margin-bottom: -12px;
  position: relative;
  right: 0;
  left: 0;
} */

#globalFooter h3 {
  padding-bottom: 20px;
  background: var(
    --gradients-buska,
    linear-gradient(130deg, #ffd788 28.79%, #ffbfbf 64.77%, #dda5ff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  margin-right: 60px;
  font-family: Mochiy Pop One;
  font-size: 38.639px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
}

.alignHorizon {
  display: flex;
  align-items: center;
  justify-content: center;
}

#globalFooter .wrap ul li:not(:last-of-type):after {
  margin-left: 30px;
  content: "";
  display: inline-block;
  height: 0px;
  width: 10px;
  background-color: #fff;
  vertical-align: middle;
}

.smallText {
  padding-top: 30px;
  font-size: 18px;
  color: #9d9d9d;
  text-align: center;
  /* margin-left: 23%; */
}

.ReallysmallText {
  color: var(--gray-0, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.footer-badge {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 10px;
  z-index: 99999;
}

.subFooter {
  color: #000000;
  padding: 10px;
  background: #ffd600;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.6px; /* 160% */
  letter-spacing: 1px;
  text-transform: uppercase;
}

.subFooter p {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 1024px) {
  #globalFooter {
    margin-top: 80px;
    padding: 20px 0 0px 0;
  }
  #globalFooter .wrap ul li:last-of-type {
    display: block;
    margin-top: 20px;
  }
  #globalFooter .wrap ul li:not(:last-of-type):after {
    display: none;
  }
  #globalFooter .wrap ul li:not(:last-of-type) {
    margin-right: 20px;
  }

  .h3footer {
    font-size: 2.5rem;
  }
  .footer-badge {
    display: none;
  }

  #globalFooter .wrap > img {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .alignHorizon {
    flex-direction: column; /* Empile les éléments verticalement */
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne */
  }

  #globalFooter h3 span {
    display: block; /* Cela forcera chaque mot à passer à la ligne */
    margin-right: 0; /* Aucun espacement sur le côté droit n'est nécessaire */
  }

  .footerPreImg {
    margin-top: 20px;
  }
}
