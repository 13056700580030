.ChangePasswordMain {
  padding-top: 150px;
}

.ChangePasswordMain h1 {
  margin-bottom: 30px;
  font-size: 3rem;
  color: #000;
}

.ChangePasswordMain p {
  color: #000;
}

.ChangePasswordMain input[type="email"],
input[type="text"],
input[type="password"] {
  width: 95%;
  padding: 10px;
  height: 56px;
  background-color: #f2f2f2;
  border-radius: 0px 10px 10px 10px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 15px;
  color: #000;
}

/* .ChangePasswordMain .wrap {
    max-width: 1024px;
    width: 75%;
    background: #1C1C2B;
    border-radius: 10px;
    padding: 80px 0;
}

.ChangePasswordMain .wrap input {
    color: #fff;
    background-color: rgba(212,220,255,0.1);
    border-color: rgba(212,220,255,0);
}
.ChangePasswordMain .wrap input:focus {
    border-color: rgba(212,220,255,1);
    background-color: rgba(212,220,255,0.1);
}

 */

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .ChangePasswordMain .wrap {
    max-width: 90%;
    width: 90%;
    padding: 40px 20px;
  }
}
